import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterWrapper = styled.footer`
  background-color: #080810;
  color: #fff;
  padding: 4rem 2rem 2rem;
  position: relative;
  overflow: hidden;
`;

const BackgroundDecoration = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 10% 90%, rgba(74, 0, 224, 0.1) 0%, transparent 40%),
    radial-gradient(circle at 90% 10%, rgba(138, 43, 226, 0.1) 0%, transparent 40%);
  pointer-events: none;
`;

const FooterContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
`;

const FooterSection = styled.div`
  flex: 1;
  min-width: 200px;
`;

const FooterTitle = styled.h3`
  color: #00c6ff;
  margin-bottom: 1rem;
  font-size: 1.2rem;
`;

const FooterList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FooterListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const FooterLink = styled(Link)`
  color: #b3b3b3;
  text-decoration: none;
  transition: color 0.3s ease;

  &:hover {
    color: #00c6ff;
  }
`;

const Copyright = styled.div`
  text-align: center;
  margin-top: 3rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #b3b3b3;
  font-size: 0.9rem;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <BackgroundDecoration />
      <FooterContent>
        <FooterSection>
          <FooterTitle>NextAIStudio</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLink to="/">Home</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services">Services</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/contact">Contact</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Services</FooterTitle>
          <FooterList>
            <FooterListItem>
              <FooterLink to="/services#web-development">Web Development</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#seo-optimization">SEO Optimization</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#social-media-marketing">Social Media Marketing</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#ppc-advertising">PPC Advertising</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#content-marketing">Content Marketing</FooterLink>
            </FooterListItem>
            <FooterListItem>
              <FooterLink to="/services#ai-integration">AI Integration</FooterLink>
            </FooterListItem>
          </FooterList>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Connect</FooterTitle>
          <p>info.nextaistudio@gmail.com</p>
         
        </FooterSection>
      </FooterContent>
      <Copyright>
        © {new Date().getFullYear()} NextAIStudio. All rights reserved.
      </Copyright>
    </FooterWrapper>
  );
};

export default Footer;