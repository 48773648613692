import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';

const NavbarWrapper = styled(motion.header)`
  background: rgba(10, 10, 20, 0.6);
  backdrop-filter: blur(10px);
  padding: 0.75rem 2rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 198, 255, 0.1);

  @media (max-width: 768px) {
    padding: 0.75rem 1rem;
  }
`;

const LogoLink = styled(Link)`
  text-decoration: none;
  color: #F5F5F5;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 1px;

  .ai {
    color: #00c6ff;
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  color: #F5F5F5;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  padding: 0.5rem 0;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #00c6ff;
    transition: width 0.3s ease;
  }

  &:hover {
    color: #00c6ff;

    &::after {
      width: 100%;
    }
  }
`;

const MenuToggle = styled.button`
  display: none;
  background: none;
  border: none;
  color: #F5F5F5;
  font-size: 1.8rem;
  cursor: pointer;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MobileMenu = styled(motion.div)`
  position: fixed;
  top: 70px;
  left: 0;
  right: 0;
  background: rgba(10, 10, 20, 0.95);
  backdrop-filter: blur(10px);
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 198, 255, 0.1);
`;

const MobileNavLink = styled(Link)`
  display: block;
  color: #F5F5F5;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 1rem 0;
  transition: color 0.3s ease;

  &:hover {
    color: #00c6ff;
  }
`;

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Services', path: '/services' },
    { name: 'Contact', path: '/contact' },
  ];

  return (
    <NavbarWrapper
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <LogoLink to="/">Next<span className="ai">AI</span>Studio</LogoLink>
      <NavLinks>
        {navItems.map((item) => (
          <NavLink key={item.name} to={item.path}>
            {item.name}
          </NavLink>
        ))}
      </NavLinks>
      <MenuToggle onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? <RiCloseLine /> : <RiMenu3Line />}
      </MenuToggle>
      <AnimatePresence>
        {isMenuOpen && (
          <MobileMenu
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {navItems.map((item) => (
              <MobileNavLink
                key={item.name}
                to={item.path}
                onClick={() => setIsMenuOpen(false)}
              >
                {item.name}
              </MobileNavLink>
            ))}
          </MobileMenu>
        )}
      </AnimatePresence>
    </NavbarWrapper>
  );
}

export default Navbar;