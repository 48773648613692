import React from 'react';
import styled, { keyframes } from 'styled-components';

// 1. Rotating Circle (Kept from original)
const rotateCircle = keyframes`
  0% { transform: rotate(0deg); }
  50% { transform: rotate(180deg); }
  75% { transform: rotate(270deg); }
  100% { transform: rotate(360deg); }
`;

const RotatingCircle = styled.div`
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #4A00E0;
  border-radius: 50%;
  animation: ${rotateCircle} 2s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
`;


// Wrapper component to use in your app
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingAnimation = () => {
  return (
    <LoadingWrapper>
      <RotatingCircle />
    
    </LoadingWrapper>
  );
};

export default LoadingAnimation;