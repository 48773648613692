import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import Header from './components/Header';
import Footer from './components/Footer';
import LoadingAnimation from './components/LoadingAnimation';
import ScrollToTop from './components/ScrollToTop';


// Lazy load route components
const Home = lazy(() => import('./pages/Home'));
const Services = lazy(() => import('./pages/Services'));
const Contact = lazy(() => import('./pages/Contact'));
const ErrorPage = lazy(() => import('./components/Error'));

const AppWrapper = styled.div`
  font-family: Arial, sans-serif;
  color: #333333;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const MainContent = styled.main`
  flex: 1;
`;

function App() {
  return (
    <Router>
      <ScrollToTop />
      <AppWrapper>
        <Header />
        <MainContent>
          <Suspense fallback={<LoadingAnimation />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Suspense>
        </MainContent>
        <Footer />
      </AppWrapper>
    </Router>
  );
}

export default App;